import {
  Link,
  Tooltip,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Skeleton,
  alpha,
  Box,
  ListItemIcon,
} from '@mui/material'
import { RiskCategoryIcon } from 'components'
import { DataSourceType } from 'typescript-axios'
import { useGetDataSourcesInSystem, ExtendedRefDataSourceProps } from 'hooks'
import config from 'constants/config'
import { ManageSearchOutlined as ViewContextIcon } from '@mui/icons-material'
import { DataFeedItem } from '../../helpers'
import StatusChip from '../StatusChip'

interface Props {
  result: DataFeedItem['result']
  type: DataFeedItem['type']
  subscription: DataFeedItem['subscription']
  status: DataFeedItem['status']
}

const ResultCell = ({ result, type, subscription, status }: Props) => {
  const { data: dataSources, isLoading } = useGetDataSourcesInSystem({
    enabled: type === 'data_entry' || type === 'claim',
  })
  const findDataSources =
    result && 'dataSourceIds' in result
      ? result.dataSourceIds
          .map((x) => dataSources?.find((y) => y.id === x))
          .filter((x): x is ExtendedRefDataSourceProps => Boolean(x))
      : []

  if (result && type === 'data_entry' && 'keyValues' in result) {
    if (isLoading) {
      return (
        <ListItem
          sx={{
            padding: 0,
            maxWidth: 400,
          }}
        >
          <ListItemAvatar sx={{ minWidth: 40 }}>
            <Skeleton variant="circular" width={24} height={24} />
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton width="80%" />}
            secondary={<Skeleton width="50%" />}
            sx={{
              margin: 0,
            }}
          />
        </ListItem>
      )
    }
    // Multiple data sources => input required
    if (findDataSources && findDataSources.length > 1) {
      return <h1>problem</h1>
    }
    const dataSource = findDataSources.length === 1 ? findDataSources[0] : undefined

    if (!dataSource) return null

    const newImageUrl = dataSource.imgUrl
      ? `${config.largeMediaUrl}${dataSource.imgUrl}?nf_resize=fit&w=24&h=24`
      : undefined

    return (
      <ListItem
        sx={{
          padding: 0,
          maxWidth: 400,
        }}
      >
        <ListItemAvatar sx={{ minWidth: 40 }}>
          <Avatar
            variant="rounded"
            src={newImageUrl}
            alt={dataSource.name}
            sx={{
              color: 'text.primary',
              fontWeight: 600,
              width: 24,
              height: 24,
              backgroundColor: newImageUrl ? 'transparent' : 'backgroundDark',
            }}
          >
            <RiskCategoryIcon type={dataSource.dataSourceType || ''} size={18} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">
              <strong>{dataSource.name}</strong>
            </Typography>
          }
          secondary={
            <Typography variant="body2">
              {dataSource.dataSourceType && dataSource.dataSourceType === DataSourceType.Custom
                ? 'Data source'
                : dataSource.dataSourceType}
            </Typography>
          }
          sx={{
            margin: 0,
            minWidth: 230,
          }}
        />
      </ListItem>
    )
  }
  if (result && type === 'domain' && 'result' in result) {
    return (
      <ListItem sx={{ p: 0 }}>
        <ListItemIcon>
          <Tooltip
            title={
              'manuallyProvided' in result
                ? 'The website was manually provided'
                : result.resultMetadata && (
                    <Box sx={{ color: 'common.white' }}>
                      <Typography variant="body2" color="inherit">
                        Website verdict:
                      </Typography>
                      <Typography variant="body2" color="inherit" sx={{ pt: 1.5 }}>
                        <i>{result.resultMetadata.discovery.response}</i>
                      </Typography>
                      <Typography variant="body2" color="inherit" sx={{ pt: 1.5 }}>
                        Pages visited:
                      </Typography>
                      {result.resultMetadata.discovery.citations?.map((citation, index) => (
                        <Typography variant="body2" color="inherit" key={citation} sx={{ pt: 1.5 }}>
                          <i>
                            [{index + 1}] {citation}
                          </i>
                        </Typography>
                      ))}
                      <Typography variant="body2" color="inherit" sx={{ pt: 1.5 }}>
                        Validation verdict:
                      </Typography>
                      <Typography variant="body2" color="inherit" sx={{ pt: 1.5 }}>
                        <i>{result.resultMetadata.validation.response}</i>
                      </Typography>
                      <Typography variant="body2" color="inherit" sx={{ pt: 1.5 }}>
                        Pages visited:
                      </Typography>
                      {result.resultMetadata.validation.citations?.map((citation, index) => (
                        <Typography variant="body2" color="inherit" key={citation} sx={{ pt: 1.5 }}>
                          <i>
                            [{index + 1}] {citation}
                          </i>
                        </Typography>
                      ))}
                    </Box>
                  )
            }
          >
            <IconButton size="small" sx={{ mr: 0.5 }}>
              <ViewContextIcon color="primary" />
            </IconButton>
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                window.open(
                  result.result.includes('http') ? result.result : `https://${result.result}`,
                  '_blank'
                )
              }}
              variant="body2"
              sx={{
                cursor: 'pointer',
                color: 'text.primary',
                textDecoration: 'underline',
                '&:hover': { color: (theme) => alpha(theme.palette.text.primary, 0.8) },
              }}
              noWrap
            >
              <strong>
                {result.result.replace('https://', '').replace('http://', '').replace('www.', '')}
              </strong>
            </Typography>
          }
          secondary={
            status !== 'declined' &&
            status !== 'rejected' &&
            status !== 'approved' &&
            status && <StatusChip status={status} />
          }
        />
      </ListItem>
    )
  }
  if (result && type === 'supplier_alias' && 'alias' in result) {
    return <Typography variant="body2">{result.alias}</Typography>
  }
  if (result && type === 'claim' && 'dataSourceIds' in result) {
    return <>claim</>
  }

  return null
}

export default ResultCell
